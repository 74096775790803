import React from "react"
import Footer from "./footer"
import Navigation from "./Navigation"

export default function Layout({ children }) {
  return (
    <div>
      <Navigation />
      <main>{children}</main>
      <Footer />
    </div>
  )
}
