import React from "react"
import styles from "../styles/mobileHeader.module.scss"
import { Link } from "gatsby"

export default function MobileHeader() {
  return (
    <Link to="/" className={styles.mobileHeader}>
      Milos Popovic
    </Link>
  )
}
