import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import layoutStyles from "../styles/layout.module.scss"
import moonIcon from "../assets/moon.svg"
import { motion } from "framer-motion"
import MobileHeader from "./MobileHeader"

export default function Navigation() {
  const [isMobileMenuVisible, setMobileMenuVisibility] = useState(false)
  const windowGlobal = typeof window !== "undefined" && window
  const isMobileView = windowGlobal.innerWidth <= 768

  useEffect(() => {
    let theme

    const windowGlobal = typeof window !== "undefined" && window

    if (windowGlobal.localStorage) {
      theme = windowGlobal.localStorage.getItem("theme")
    }

    if (theme === "dark") {
      document.body.classList.add(theme)
    } else {
      localStorage.setItem("theme", "light")
    }
  }, [])

  const switchTheme = e => {
    const body = document.body
    const darkThemeClass = "dark"

    if (body.classList.contains(darkThemeClass)) {
      body.classList.remove(darkThemeClass)
      localStorage.setItem("theme", "light")
    } else {
      body.classList.add(darkThemeClass)
      localStorage.setItem("theme", "dark")
    }
  }

  let initial = isMobileView ? { left: "-100%" } : { left: "0" }
  let animate =
    isMobileView && isMobileMenuVisible
      ? { left: "0" }
      : isMobileView && !isMobileMenuVisible
      ? { left: "-100%" }
      : {}

  return (
    <>
      <MobileHeader />
      <motion.div id={layoutStyles.menu} initial={initial} animate={animate}>
        <Link className={layoutStyles.logoText} to="/">
          Milos Popovic
        </Link>
        <Link activeClassName={layoutStyles.active} to="/">
          <div>home</div>
        </Link>
        <Link activeClassName={layoutStyles.active} to="/blog/">
          <div>blog</div>
        </Link>
        <Link activeClassName={layoutStyles.active} to="/visualizations/">
          <div>data visualizations</div>
        </Link>
        <Link activeClassName={layoutStyles.active} to="/maps/">
          <div>maps</div>
        </Link>
        <Link activeClassName={layoutStyles.active} to="/research/">
          <div>research</div>
        </Link>
        <Link activeClassName={layoutStyles.active} to="/media/">
          <div>media</div>
        </Link>
        <Link activeClassName={layoutStyles.active} to="/contact/">
          <div>contact</div>
        </Link>
        <button id={layoutStyles.darkMode} onClick={e => switchTheme(e)}>
          <img
            className={layoutStyles.darkModeIcon}
            src={moonIcon}
            alt="dark mode icon"
          />
        </button>
        <button
          id={layoutStyles.mobileClose}
          onClick={() => setMobileMenuVisibility(!isMobileMenuVisible)}
        >
          &#x2716;
        </button>
      </motion.div>
      <button
        className={layoutStyles.mobileToggle}
        onClick={() => {
          setMobileMenuVisibility(!isMobileMenuVisible)
        }}
      >
        {!isMobileMenuVisible && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
          </svg>
        )}
        {isMobileMenuVisible && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        )}
      </button>
    </>
  )
}
